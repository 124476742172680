import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantsUrl } from './utils/constants-url';
import { Subject, BehaviorSubject, Subscription, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private _accessToken;
  public get accessToken() {
    return this._accessToken;
  }
  public set accessToken(value) {
    this._accessToken = value;
  }

  private _languageCode;
  public get languageCode() {
    return this._languageCode;
  }
  public set languageCode(value) {
    this._languageCode = value;
  }
  private _userProfileDetails;
  public get userProfileDetails() {
    return this._userProfileDetails;
  }
  public set userProfileDetails(value) {
    this._userProfileDetails = value;
  }

  private _testingSites;
  public get testingSites() {
    return this._testingSites;
  }
  public set testingSites(value) {
    this._testingSites = value;
  }

  private _selectedTestSiteDetails;
  public get selectedTestSiteDetails() {
    return this._selectedTestSiteDetails;
  }
  public set selectedTestSiteDetails(value) {
    this._selectedTestSiteDetails = value;
  }

  private _timeDetails;
  public get timeDetails() {
    return this._timeDetails;
  }
  public set timeDetails(value) {
    this._timeDetails = value;
  }

  private _locationDetails;
  public get locationDetails() {
    return this._locationDetails;
  }
  public set locationDetails(value) {
    this._locationDetails = value;
  }

  profileDetails = new BehaviorSubject<any>(null);
  siteAndTime = new Subject();
  checkInData = new Subject();
  reportData = new Subject();
  loadMonitor = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) { }

  //--ProdApi token method

  // detailsByToken(accessToken : any){
  //   return this.http.get(ConstantsUrl.BASE_URL_PROCTOR + ConstantsUrl.drupalUrl + "/TokenAuthentication?AccessToken=" + accessToken)
  // }

  //--TestApi token method

  detailsByToken(accessToken: any) {
    return this.http.get(ConstantsUrl.MICRO_SERVICE_URL + ConstantsUrl.drupalUrl + "/TokenAuthentication" , accessToken)
  }

  tokenvalidate(accessToken) {
    let url = ConstantsUrl.MICRO_SERVICE_URL + ConstantsUrl.TOKEN_AUTHENTICATION + 'AccessToken=' + accessToken;
    return this.http.get(url);
  }

  errorLog(body) {
    let url = ConstantsUrl.API_CRM + ConstantsUrl.ERROR_LOGS;
    return this.http.post(url, body)
  }
}
