import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">	&copy; <b><a href="https://provexam.com" target="_blank">Prov, Inc</a></b> {{currentYear}}</span>
  `,
})
export class FooterComponent {
  currentYear = new Date().getFullYear();
}
