import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppService } from '../app.service';

@Injectable()

export class HttpInterceptorsConfig implements HttpInterceptor {
    public accessToken: any;

    constructor( private _appService: AppService){}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.accessToken = this._appService.accessToken;
        request = request.clone({
            setHeaders: {
                'Authorization': `Bearer ${this.accessToken}`,
            }
        });
        return next.handle(request);
    }
}