import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AppService } from '../../../app.service';
import { ConstantsUrl, httpOptions } from '../../../utils/constants-url';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { NgSelectConfig } from '@ng-select/ng-select';
import { ThemeSpinner } from '../spinner/spinner.service';
import { HttpHeaderService } from '../../../http-header.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  currentTheme = 'default';
  defaultPic = 'assets/icons/default-pic.png';
  profileDetails: any;
  userMenu = [{ title: 'Profile' }, { title: 'Log out' }];
  subjectDestoryer: any;
  userName: string;
  testingSites: Array<any> = [];
  testSiteLoading: boolean = true;
  selectedSiteDetails: any;
  locationsDetails: any;
  date = new Date();
  localStorageSite: any;

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private _appService: AppService,
    private http: HttpClient,
    private config: NgSelectConfig,
    private spinner: ThemeSpinner,
    private _HttpHeaderService : HttpHeaderService,
  ) {
    this.themeService.changeTheme('corporate');
    this.subjectDestoryer = this._appService.profileDetails.subscribe(data => {
      if (data != null && data.DrupalRoleID != null) {
        this.profileDetails = data;
        this.userName = this.profileDetails.UserName;
        this.getArchiveTestDetails();

        var a = localStorage.getItem('site');
        if (a != undefined && this.profileDetails != undefined) {
          this.localStorageSite = JSON.parse(a);
          this.testingSites.push(this.localStorageSite);
          this.testSiteLoading = false;
          //this.selectedSiteCenter(this.localStorageSite);
        }
      }
    })
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  getArchiveTestDetails() {
    this.spinner.show();
    let uniformLocator = ConstantsUrl.MICRO_SERVICE_URL + ConstantsUrl.EXAM_TESTINGSITE_V1 + "?username=" + this.profileDetails.UserName + "&password=" + this.profileDetails.PasswordHash + "&includeDetails=" + "Y"
    this.http.get(uniformLocator).subscribe((data: any) => {
      this.spinner.hide();
      if (data.validUser) {
        var n = data.sites;
        n = n.map(details => {
          return {
            siteConcat: details.siteName + " - " + details.siteType,
            siteName: details.siteName,
            siteType: details.siteType,
            id: details.siteIdentifier,
          }
        })
        this.testSiteLoading = false;
        this.testingSites = n;
        this._appService.testingSites = data;
          let body = {
            "UserName": this.profileDetails.UserName,
            "Password": this.profileDetails.PasswordHash
          }
           this._HttpHeaderService.getToken(body)
      }
    })
  }

  public selectedSiteCenter(value) {
    if (value != undefined) {
      localStorage.setItem('site', JSON.stringify(value));
      this.selectedSiteDetails = value;
      switch (value.siteType) {
        case 'NCCERPractical':
          value.siteTypeKey = 'NP'
          break;
        case 'NCCERAssessment':
          value.siteTypeKey = 'NA'
          break;
        case 'Prov':
          value.siteTypeKey = 'PR'
          break;
        case 'TMS':
          value.siteTypeKey = 'TM'
          break;
      }
      this._appService.selectedTestSiteDetails = value;
      this.detailsFromArchieve(value);
    }
  }

  async detailsFromArchieve(value) {
    this.spinner.show();
    var params = {
      id: this.profileDetails.UserName,
      siteId: value.id,
      testdatetime: moment.utc(this.date).format('YYYY-MM-DD')
    }
    await this.locationDetails(value).then(data =>{
      this.http.put(ConstantsUrl.BASE_URL_PROCTOR + ConstantsUrl.PROCTOR_SCHEDULE + "/GetSchedulesByProctorId", params, httpOptions)
      .subscribe(data => {
        this.spinner.hide();
        this._appService.timeDetails = data;
        var n = {
          key: 'dateAndTime',
          value: data,
          siteDetails: value,
          userDetails: this.profileDetails,
          locationDetails: this.locationsDetails
        };
        this._appService.siteAndTime.next(n);
      },
        error => console.log(error)
      );
    }).catch(error => console.log('Location details failed to load'))
  }

  locationDetails(value) {
    //location details
    var n = new Promise((resolve, reject) => {
      this.http.get(ConstantsUrl.BASE_URL + ConstantsUrl.EXAM_TESTINGSITE + "/getTestingSiteObject?testingsiteid=" + value.id)
        .subscribe(data => {
          resolve(data);
          this.locationsDetails = data;
        }, error => reject(error))
    })
    return n;
  }


  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.subjectDestoryer) {
      this.subjectDestoryer.unsubscribe();
    }
  }

}
