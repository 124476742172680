import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

export class ConstantsUrl {

    // public static BASE_URL = environment.BASE_URL;
    // public static BASE_URL_PROCTOR = environment.BASE_URL_PROCTOR;
    // public static BASE_URL_SCHEDULER = environment.BASE_URL_SCHEDULER;
    // public static API_CRM = environment.API_CRM;
    // public static MICRO_SERVICE_URL = environment.MICRO_SERVICE_URL;
    // public static SCHEDULER_URL = environment.SCHEDULER_URL;

    //-- ProdAPI
   public static BASE_URL = "https://valhallamasterapi.provexam.com/";
   public static BASE_URL_PROCTOR = "https://proctorconsoleapi.provexam.com/";
   public static BASE_URL_SCHEDULER = "https://schedulerapi.provexam.com/";
   public static API_CRM = "https://valhallacrmapi.provexam.com/";
   public static MICRO_SERVICE_URL = "https://proctorconsolemicroservice.provexam.com";
   public static SCHEDULER_URL = "https://scheduler.provexam.com/";

    //-- TestAPI
    //  public static BASE_URL = "https://testvalhallamasterapi.examroom.ai:4433/";
    //  public static BASE_URL_PROCTOR = "https://testvalhallaproctorconsoleapi.examroom.ai:4438/";    
    //  public static BASE_URL_SCHEDULER = "https://testvalhallaschedulerapi.examroom.ai:4439/";
    //  public static API_CRM = "https://testvalhallacrmapi.examroom.ai:4431/";
    //  public static MICRO_SERVICE_URL = "https://testproctorconsolemicroservice.examroom.ai:4434";
    //  public static SCHEDULER_URL = "https://testschedulerui.examroom.ai:4444/";
    
    // ------------------
    public static drupalUrl: string = "/api/1/drupal";
    public static version: string = "/api/1/";
    public static PAGES_SCHEDULER = "#/pages/scheduler";
    public static TOKEN_AUTHENTICATION = "/api/1.0/User/TokenAuthentication?";
    public static EXAM_TESTINGSITE = "api/examtestingsite";
    public static EXAM_TESTINGSITE_V1 = "/api/1.0/User/TestingSite";
    public static PROCTOR_SCHEDULE = "api/proctorschedule";
    public static USER_SETTINGS = "api/usersettings";
    public static TEST_TAKER = "api/testtaker";
    // public static TEST_TAKER = "api/1/testtaker";
    public static CANDIDATE_SCHEDULER = "api/candidateschduler";
    public static TEST_SESSION = "api/examtestsession";
    public static TEST_SESSION_PC = "api/pc/examtestsession";
    public static TEST_SESSION_PACKAGE = "api/examtestsessionpackage";
    public static EXAM_TESTINGSITE_USERSEX = "api/examtestingsiteusersex";
    public static PC_DRUPAL = "api/pc/drupal";
    public static CANDIDATE_DOCUMENT = "api/examTestCandidateDocument";
    // public static CANDIDATE_DOCUMENT = "api/1/ExamTestCandidateDocument/CreateExamCandidateDocument";
    public static GET_TEST_TEKER_V1 = "api/testtaker/GetTestTakerV1";
    public static PROCTOR_NOTES = "api/pc/ProctorNote";
    public static UPDATE_NO_SHOW = "api/PC/UpdateNoShow";
    public static GET_AVAILABLE_SCHEDULE_DATE = "api/PC/GetAvailableScheduleDate";
    public static CHANGE_TEST_SESSION_STATUS = "api/valhalla/ChangeTestSessionStatus";
    public static ACCESS_CODE_UPDATE = "api/examtestingsiteusersex/accesscodeupdate";
    public static GET_TEST_PACKAGE_DOCUMENTS = "api/TC/GetTestPackageDocuments";
    public static GET_TOKEN = "api/Dev/OauthToken";
    public static EXAM_TEST_SESSION = "api/examtestsession";
    public static CREATE_TEST_SESSION_BY_ID = "api/arkiv/CreateTestSessionByID";
    public static RECACHE_TEST_PACKAGE = "api/TC/RecacheTestPackage";
    public static SYNC_EXAM_KEY = "api/TC/SyncExamKey";
    public static ERROR_LOGS = "api/Dev/TS_ErrorLogs";
    public static PENDING_SCORE = '/api/1.0/PendingScoring';
    public static RECENTCANDIDATE_PHOTO ='api/examTestCandidateDocument/GetRecentCandidatePhoto'
    static BASE_URL2: string;
    static PROCTOR_API: string;
    public static CHANGE_SESSION_STATUS = "api/valhalla/ChangeTestSessionStatus";
}

export let httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
}