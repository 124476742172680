import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { ConstantsUrl,httpOptions } from './utils/constants-url';

@Injectable({
  providedIn: 'root'
})
export class HttpHeaderService {
  token: string;
  token_type: string;
  constructor(private http: HttpClient) { }

  public getToken(body) {

 
 let url = ConstantsUrl.API_CRM + ConstantsUrl.GET_TOKEN;

    return this.http.post(url,body,httpOptions ).subscribe(res => {
       if(res["access_token"]){
        this.token = res["access_token"];
        this.token_type = res["token_type"];
        localStorage.setItem('token',res["access_token"]);
       }
    }, error => {
      console.log("error", error)
    });
  }
  
  public getHeader() {
    let HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "Authorization":  'Bearer ' + this.token
      })
    };
    return HttpOptions
  }
}